<template>
  <div class="font-montserrat d-flex flex-column choose-role-content">
    <div class="child-header mrg-bottom-16">
      {{ isBrand ? 'Manage your orders with ease' : 'Access your favourite brands with a click of a button' }}
    </div>
    <!-- form -->
    <validation-observer>
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          #default="{ errors }"
          :name="getLabel()"
          :vid="`name`"
          rules="required|min:3"
        >
          <b-form-group
            class="custom-input-group mrg-bottom-16"
            :class="
              isDirtyName
                ? errors.length > 0
                  ? 'invalid-input-group'
                  : 'valid-input-group'
                : ''
            "
          >
            <b-form-input
              v-model="signupDetails.entityName"
              class="custom-input text-capitalize"
              type="text"
              :placeholder="getLabel()"
            />
            <span
              class="custom-input-placeholder text-capitalize font-montserrat"
              :data-content="getLabel()"
            >
              {{ getLabel() }}
            </span>
            <validation-icon-with-tooltip
              id="name"
              class="kp-validation-icon"
              :show="isDirtyName"
              :errors="errors"
            />
          </b-form-group>
        </validation-provider>
        <b-button
          type="submit"
          class="font-inter custom-btn-submit mrg-bottom-16"
          variant="info"
          :disabled="signupDetails.entityName < 3"
          block
        >
          Next Step
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import {
 BButton, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { AUTH_REGISTER_CHILDREN } from '@/router/routes/pages'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ValidationIconWithTooltip from '@/@core/components/icon-with-tooltip-error/ValidationIconWithTooltip.vue'
import { mapState } from 'vuex'

const { ROLES } = constants

const maxNames = 15

export default {
  name: 'AddUser',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ValidationIconWithTooltip,
  },
  data() {
    return {
      ROLES,
      maxNames,
      // validation
      isDirtyName: false,
      required,
    }
  },
  computed: {
    ...mapState({
      signupDetails: state => state.signup.signupDetails,
    }),
    signupRole() {
      return this.signupDetails.role
    },
    isBrand() {
      return this.signupRole === ROLES.BRAND
    },
  },
  watch: {
    signupDetails: {
      handler(val) {
          if (val.entityName) {
            // for validation
            this.isDirtyName = true
          }
      },
      deep: true,
    },
  },
  methods: {
    getLabel() {
      return `${this.isBrand ? 'Brand' : 'Store'} Name`
    },
    onSubmit() {
      this.$router.push({ name: AUTH_REGISTER_CHILDREN.ENTER_DETAILS })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/pages/signup/kp-signup.scss';
</style>
